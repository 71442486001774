app-toast {

    position: fixed;
    top: 0;
    right: 0;
    margin: 16px;

    .toaster {
        padding-bottom: 5px;

        .toast {
            padding: 3px 2px;
            color: white;

            .btn-close {
                filter: brightness(0) saturate(100%) invert(100%) sepia(97%) saturate(18%) hue-rotate(240deg) brightness(104%) contrast(100%);
            }

            &.danger-toast {
                background-color: $color-warn;
            }

            &.success-toast {
                background-color: $color-secondary-light;
            }

            &.warning-toast {
                background-color: $color-warn;
            }

            &.general-toast {
                background-color: $color-white;
                color: $color-black;
            }
        }
    }
}