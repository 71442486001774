.tabs {
    mat-tab-header {
        margin-left: 8%;

        @include respond(phone) {
            margin-left: 0;
         }
    }

    .mat-mdc-tab:hover .mdc-tab__ripple::before {
        opacity: 0;
    }

    .mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab {
        flex-grow: 0;
    }

    .mdc-tab {  
        &:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
            color: $color-black;

            &:hover {
                color: $color-black;
                text-decoration: none;;
            }
        } 

        &--active {
            background: $color-white;

            &:hover {
                cursor: none;
            }
        }

        .mdc-tab-indicator {
            display: none;
        }

        .mdc-tab__text-label {
            color: $color-primary-light;
            font-size: 2.2rem;
            font-weight: $font-weight-regular;

            &:hover {
                color: $color-primary-hover;
                text-decoration: underline;
            }
        }
    }
    .mat-mdc-tab-body-wrapper {
        background: $color-white;
    }
}