*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}


/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
    margin: 0;
}

html {
    font-size: 62.5%;

    font-weight: $font-weight-regular;
}

body {
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    width: 100%;
    height: 100%;
}

ul {
    list-style: none;
    padding-left: 0px;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: inherit;
}



a {
    text-decoration: none;

    &:hover {
        cursor: pointer;
    }
}



// Google Autocomplete Overrides
.pac-container:after {
    /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */

    background-image: none !important;
    height: 0px;
}

// Angular Material Style Overrides


// Checkbox overrides 
.mat-mdc-checkbox.mat-primary {
    --mdc-checkbox-unselected-focus-icon-color: #CECECE;
    --mdc-checkbox-unselected-hover-icon-color: #4685c5;
    --mdc-checkbox-disabled-selected-icon-color: #CECECE;
    --mdc-checkbox-disabled-unselected-icon-color: #CECECE;
    --mdc-checkbox-unselected-icon-color: #CECECE;
    --mdc-checkbox-unselected-pressed-icon-color: #CECECE;
}

.mdc-checkbox__ripple {
    display: none;
}



//Radio Button Overrides
.mat-mdc-radio-button .mdc-radio:hover .mdc-radio__native-control:not([disabled]):not(:focus)~.mdc-radio__background::before {
    display: none;
}

.mat-mdc-radio-button.mat-primary {
    --mdc-radio-disabled-selected-icon-color: #CECECE;
    --mdc-radio-disabled-unselected-icon-color: #CECECE;
    --mdc-radio-unselected-hover-icon-color: #4685c5;
    --mdc-radio-unselected-icon-color: #CECECE;
    --mdc-radio-unselected-pressed-icon-color: #CECECE;
    --mdc-radio-selected-focus-icon-color: #1e88e5;
    --mdc-radio-selected-hover-icon-color: #1e88e5;
    --mdc-radio-selected-icon-color: #1e88e5;
    --mdc-radio-selected-pressed-icon-color: #1e88e5;
    --mat-mdc-radio-checked-ripple-color: #1e88e5;
}