.details {
    margin-top: 10rem;

    &__box {
        margin-bottom: 5rem;
        .label {
            color: $color-medium-grey;
            font-size: $font-20;
            font-weight: $font-weight-regular;
            margin-bottom: 1rem;

            &::after {
                content: '';
                display: block;
                border-bottom: 1px solid $color-light-grey;
                margin-top: 1px;
            }
        }
        .content {
             & * {
                color: $color-black;
                font-size: $font-16;
                font-weight: $font-weight-regular;
             }
             
             .list {
                list-style: none;
                padding: 0;
             }

             a {
                color: $color-primary-light;
            }

        }
    }
}