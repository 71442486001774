.icon-small-blue {
    width:2rem;
    filter: brightness(0) saturate(100%) invert(46%) sepia(75%) saturate(403%) hue-rotate(169deg) brightness(91%) contrast(89%);
}

.icon-small-grey {
   width: 2rem;
   filter: brightness(0) saturate(100%) invert(29%) sepia(16%) saturate(8%) hue-rotate(337deg) brightness(99%) contrast(84%);
}

.pull-element-y-sm {
    margin-top: -3rem;

    @include respond(phone) {
        margin-top: -1rem
     }
}

h1, .h1 {
    font-size: $font-20;
    font-weight: $font-weight-regular;

    @include respond(phone) {
        font-size: $font-14;
     }
}


h2, .h2 {
    font-size: $font-24;
    font-weight: $font-weight-medium;
}

h3, .h3 {
    font-size: $font-26;
    font-weight: $font-weight-medium;

    @include respond(phone) {
        font-size: $font-16;
     }
}

h4, .h4 {
    font-size: $font-30;
    font-weight: $font-weight-regular;

    @include respond(phone) {
        font-size: $font-15;
     }
}

h5, .h5 {
    font-size: $font-45;
    font-weight: $font-weight-regular-bold;
}

h6 {
    font-size: $font-60;
    font-weight: $font-weight-medium;
}

.p1 {
    font-size: $font-10;
    font-weight: $font-weight-light;
}

.p2 {
    font-size: $font-12;
    font-weight: $font-weight-light;
}

.p3 {
    font-size: $font-14;
    font-weight: $font-weight-light;

    &--regular {
        font-weight: $font-weight-regular;
    }

    &--medium {
        font-weight: $font-weight-medium;
    }
    &--bold {
        font-weight: $font-weight-regular-bold;
    }

}

.p4 {
    font-size: $font-16;
    font-weight: $font-weight-light;

    &--regular {
        font-weight: $font-weight-regular;
    }

    &--regular-bold {
        font-weight: $font-weight-regular-bold;
    }
}


.p5 {
    font-size: $font-18;
    font-weight: $font-weight-light;

    &--regular {
        font-weight: $font-weight-regular;
    }

    &--medium {
        font-weight: $font-weight-medium;
    }

    @include respond(phone) {
        font-size: $font-14;
     }
    
}


// h1 {
//     font-size: $font-45;
//     font-weight: $font-weight-medium;
// }

// h2 {
//     font-size: $font-30;
//     font-weight: $font-weight-regular;
//     @include respond(phone) {
//         font-size: 2rem;
//      }
// }

// h3 {
//    font-size: $font-24;
// }

// h4 {
//     font-size: $font-18;
//     font-weight: $font-weight-regular;
// }


// .p1 {
//     font-size: $font-18;
// }
// .p2 {
//     font-size: $font-18;
// }
// .p3, button {
//     font-size: $font-16;
// }
// .p4 {
//     font-size: $font-16;
//     font-weight: $font-weight-light;
// }
// .p5 {
//     text-transform: uppercase;
//     font-size: $font-14;
//     font-weight: $font-weight-light;
//     color: $color-medium-grey;
// }

// .p6 {
//     font-size: $font-30;
//     font-weight: $font-weight-regular-bold;
//     @include respond(phone) {
//         font-size: $font-16;
//     }
// }

.section-title {
    font-size: $font-35;
    color: $color-grey;
     &::after {
        content: '';
        margin-top:1rem;
        border-bottom: 2px solid #dedede;
        width: 100%;
        display: block;
     }
}



.vr {
    background: $color-spacer-color;
}

.box-shadow {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}