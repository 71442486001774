// COLORS

$color-primary-light: #4685c5;
$color-primary-hover: #3d75ad;
$color-primary-dark: #0c2577;
$color-footer-blue: #286CE2;
$color-menu-header: #5E93CA;
$color-primary-blue: #153E98;

$color-secondary-blue-light: #2A4088;
$color-secondary-light: #6ca476;
$color-secondary-hover: #578860;

$color-warn: #f06a6a;
$color-error: #FF0000;
$color-error-light: #FCEAEA;

$color-black: #000000;
$color-light-black: #373737;
$color-medium-black: #282526;
$color-dark-grey: #545454;
$color-medium-grey: #888888;
$color-light-grey: #e5e5e5;
$color-extra-light-grey: #f8f8f8;
$color-grey: #9B9B9B;
$color-disabled: #CECECE;
$color-white: #ffffff;
$color-spacer-color: #888888;
$color-network-background: #ededed;
$color-network-name: #5D5B5C;
$color-card-header: #282526;
$color-transparent-white: #c7e1f8;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1900px
) !default;


//FONT SIZES

$font-60: 6rem;
$font-45: 4.5rem;
$font-36: 3.6rem;
$font-35: 3.5rem;
$font-30: 3rem;
$font-26: 2.6rem;
$font-24: 2.4rem;
$font-22: 2.2rem;
$font-18: 1.8rem;
$font-20: 2rem;
$font-16: 1.6rem;
$font-15: 1.5rem;
$font-14: 1.4rem;
$font-12: 1.2rem;
$font-10: 1rem;

$font-weight-regular: 300;
$font-weight-regular-bold: 500;
$font-weight-light: 200;
$font-weight-medium: 400;

