.faq {
  background: $color-primary-light;
  color: $color-white;
  height: auto;
  min-width: 60rem;
  max-width: 60rem;

  @include respond(phone) {
    min-width: 100%;
  }

  &__header {
    background: $color-menu-header;
    height: 8rem;

    &--title {
      margin-left: 2rem;

    }
  }


  &__wrapper {
    margin-left: 5px;

    mat-expansion-panel {
      background: none;
      color: $color-white;

      .mat-expansion-panel-body {
        padding-left: 0;
      }

      mat-expansion-panel-header {
        padding-left: 0;
        padding-right: 0;
        height: auto;

        &:hover {
          background: none !important; // temporary fix
        }

        &::after {
          border-bottom: 1px solid #91B9E2;
          content: "";
          display: block;
          position: absolute;
          right: 0;
          left: 0;
          bottom: 0;
        }

        .mat-expansion-indicator {
          &::after {
            background-image: url(../../assets/Svg/Anthem_Help_FAQ_Panel_Expand_Icon.svg);
            content: "";
            display: inline-block;
            background-repeat: no-repeat;
            padding: 12px;
            vertical-align: bottom;
            border-style: none;
            border-width: none;
            transform: rotate(0deg);
            filter: brightness(0) saturate(100%) invert(72%) sepia(9%) saturate(1180%) hue-rotate(170deg) brightness(98%) contrast(93%);
          }
          
          &:hover {
            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(338deg) brightness(97%) contrast(108%);
          }
        }
      }

      .mat-expansion-panel-content {
        font-size: 1.8rem;
      }

      .mat-expansion-indicator::after {
        color: $color-white;
      }
    }
  }

  &__content {
    &--section-title {
      text-transform: capitalize;
    }

    &--header {
      display: block;
    }

    &--text {

      img {
        margin-left: 1rem;
      }

      a {
        color: $color-white;
      }
    }
  }
}